// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import get from 'lodash-es/get';
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import DatePickerField from "../../components/103FormikCustom/DatePickerField";
import * as apiIndustry from "../../Api/_ApiIndustry";
import {addMessage} from "../../Redux/Action/messageAction";

class IndustryUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            detail: {
                name: '',
                sequence: null,
                allow_manifest_import: false,
            }
        };
    }

    componentDidMount() {
        if(this.props.id) {
            this.setState({isEdit: true})
            this.getDetail();
        } else {
            const { t } = this.props;
            const breadcrumb = [{
                title: t("IndustryManagement:addIndustry"),
                link: null
            }];
            this.props.setBreadcrumbP({breadcrumbData: breadcrumb});
        }
    }

    getDetail = () => {
        let params = {
        };
        apiIndustry.getDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data && obj.data) {
                    const detail = obj.data;
                    this.setState( {
                        detail: this.prepareData(detail)
                    });
                    const itemBreadcrumb = [{
                        title: `Edit Industry [${detail.name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: itemBreadcrumb});
                }
            } else {
                console.log(obj.error)
            }
        })
    }

    prepareData = data => {
        if(data) {
            const item = {...data};
            item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
            return item;
        }
        return null;
    };

    // BUTTON FUNCTION
    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/industry-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _createAsync = (value) => {
        const { i18n } = this.props;
        const body = {
            name: value.name,
            sequence: value.sequence,
            allow_manifest_import: value.allow_manifest_import,
        };
        apiIndustry.create(body).then(obj => {
            if (obj.status === 201) {
                const msgDsp = {
                    messageSnackbar: 'Add new industry successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/industry-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Add new industry failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _updateAsync = (value) => {
        const { i18n } = this.props;
        const body = {
            name: value.name,
            sequence: value.sequence,
            allow_manifest_import: value.allow_manifest_import,
        };
        apiIndustry.update(this.props.id, body).then(obj => {
            if (obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: 'Update industry successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/industry-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update industry failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _submitForm = (value) => {
        if(this.state.isEdit) {
            this._updateAsync(value);
        } else {
            this._createAsync(value);
        }
    }

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange, setFieldValue }) => {
        const { t, id } = this.props;
        return values && (
            <Form id="industryForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("IndustryManagement:name")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="name" type="text" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                {errors.name && touched.name ? <ErrorMessage message={errors.name} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("IndustryManagement:sequence")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="sequence" type="number" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                {errors.sequence && touched.sequence ? <ErrorMessage message={errors.sequence} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("IndustryManagement:allowManifestImport")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Field name="allow_manifest_import" type="checkbox" as={Checkbox} />
                        </Grid>
                    </Grid>

                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">{id ? 'Save' : 'Add'}</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, detail } = this.state;
        const inputDetail = {...detail, isEdit: isEdit};
        const Schema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
            sequence: Yup.number().required("Sequence is required"),
            allow_manifest_import: Yup.boolean(),
        })

        return (
            detail && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputDetail}
                            validationSchema={Schema}
                            onSubmit={this._submitForm}
                            component={this.formConfiguration}
                        />
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(IndustryUpdate)));
