import {api} from "./_ApiFactoryWithHeader";

export const apiMessage = {
    getPushNotificationTemplates: (params) => {
        const searchParams = new URLSearchParams({
            'send_type[in]': 'repeat,schedule'
        });
        return api.get(`push_notification_templates?${searchParams.toString()}`, params, null)
    },
    getPushNotificationTemplatesDetail: (id, params) => {
        return api.get(`push_notification_templates/${encodeURIComponent(id)}`, params, null)
    },
    updatePushNotificationTemplates: (id, body) => {
        return api.put(`push_notification_templates/${encodeURIComponent(id)}`, body)
    },
    deletePushNotificationTemplates: (ids, params) => {
        const searchParams = new URLSearchParams({
            'push_notification_template_id[in]': ids.join()
        });
        return api.delete(`push_notification_templates?${searchParams.toString()}}`, params, null)
    },
    pushNotification: (body) => {
        return api.post(`push_notification_templates`, body)
    },
    getWechatTemplates: (params) => {
        return api.get('wechat_templates', params, null)
    },
    sendWechatToAll: (body) => {
        return api.post(`wechat_sent_to_all`, body)
    },
};
