import {api} from './_ApiFactoryWithHeader';

const ENDPOINT = 'industry_types';
export const ID_ATTRIBUTE = 'industry_type_id';

export function getList(params, cancelToken) {
    return api.get(ENDPOINT, params, null, cancelToken);
}

export function getDetail(id, params) {
    return api.get(`${ENDPOINT}/${encodeURIComponent(id)}`, params, null, null);
}

export function create(body) {
    return api.post(ENDPOINT, body);
}

export function update(id, body) {
    return api.put(`${ENDPOINT}/${encodeURIComponent(id)}`, body);
}

export function deleteOne(id) {
    if (id == null || id == '') {
        return Promise.reject(new Error('id is empty'));
    }
    return api.delete(`${ENDPOINT}/${encodeURIComponent(id)}`);
}

export function deleteMany(ids) {
    if (ids == null || ids == '' || ids.length == 0) {
        return Promise.reject(new Error('id is empty'));
    }
    const params = new URLSearchParams({
        [`${ID_ATTRIBUTE}[in]`]: ids.join()
    });
    let paramStr = params.toString();
    if (!paramStr) {
        paramStr = new URLSearchParams({
            [`${ID_ATTRIBUTE}[null]`]: true
        }).toString();
    }
    return api.delete(`${ENDPOINT}?${paramStr}`);
}
