import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiAuction = {
    getAuctions: (params) => {
        return api.get('auction_slots', params, null)
    },
    getAuctionDetail: (id, params) => {
        return api.get(`auction_slots/${encodeURIComponent(id)}`, params, null)
    },
    addAuction: (body) => {
        return api.post(`auction_slots`, body)
    },
    updateAuction: (id, body) => {
        return api.put(`auction_slots/${encodeURIComponent(id)}`, body)
    },
    updateAuctionStatus: (id, body) => {
        return api.put(`auction_slot_status_update/${encodeURIComponent(id)}`, body)
    },
    deleteAuction: (id, params) => {
        return api.delete(`auction_slots/${encodeURIComponent(id)}`, params);
    },
    submitSealedBid: (platform, id, body) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.post(`${baseUrl}/submit_final_sealed_bid/${encodeURIComponent(id)}`, body);
        });
    },
    downloadAuctionInfo: (platform, id, option) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.getCSVFile(`${baseUrl}/download_auction_info/${encodeURIComponent(id)}`, { responseType: 'blob', ...option });
        });
    },
    downloadSealedBidInfo: (platform, id, option) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.getCSVFile(`${baseUrl}/download_sealed_bid_info/${encodeURIComponent(id)}`, { responseType: 'blob', ...option });
        });
    },
    uploadAuctionInfo: (platform, id, body) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.postMultipart(`${baseUrl}/upload_auction_info/${encodeURIComponent(id)}`, body, {});
        });
    },
    uploadSealedBidInfo: (platform, id, body) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.postMultipart(`${baseUrl}/upload_sealed_bid_info/${encodeURIComponent(id)}`, body, {});
        });
    },
    downloadSealedBidAwardInfo: (platform, id, option) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.getCSVFile(`${baseUrl}/download_sealed_bid_info/${encodeURIComponent(id)}?awarded=true`, { responseType: 'blob', ...option });
        });
    },
    completeSealedBidAward: (platform, id, body) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.post(`${baseUrl}/complete_sealed_bid_award/${encodeURIComponent(id)}`, body);
        });
    },
    getSuppliers: (params) => {
        return api.get('suppliers', params, null);
    },
    getLots: (platform, params) => {
        return getBaseUrl(platform).then(baseUrl => {
            return api.get(`${baseUrl}/auctions`, params, null);
        });
    },
};
