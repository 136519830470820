import { api } from "./_ApiFactoryWithHeader";

export const apiFile = {
  getFiles: (params) => {
    return api.get('files', params);
  },
  getFile: (id, params) => {
    return api.get(`files/${encodeURIComponent(id)}`, params);
  },
  updateFile: (id, body) => {
    return api.put(`files/${encodeURIComponent(id)}`, body);
  },
  createFile: (body) => {
    return api.post(`files`, body);
  },
  deleteFile: (id, params) => {
    return api.delete(`files/${encodeURIComponent(id)}`, params);
  },
  deleteFiles: (ids, params) => {
    const searchParams = new URLSearchParams({
      'file_id[in]': ids.join()
    });
    return api.delete(`files?${searchParams.toString()}`, params);
  },
};
