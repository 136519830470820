import {api} from './_ApiFactoryWithHeader';

const ENDPOINT = 'industry_type_essential_attributes';
export const ID_ATTRIBUTE = 'industry_type_essential_attribute_id';

export function getList(params, cancelToken) {
    return api.get(ENDPOINT, params, null, cancelToken);
}

export function getDetail(id, params) {
    return api.get(`${ENDPOINT}/${encodeURIComponent(id)}`, params, null, null);
}
